export default {
    actors: [
        {
            id: '21314',
            name: 'Mãe',
            slug: 'mother',
            active: true,
            createdAt: '2022/10/09'
        },
        {
            id: '21315',
            name: 'Pai',
            slug: 'pather',
            active: true,
            createdAt: '2022/10/09'
        }
    ]
};
