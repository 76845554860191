var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sector"},[_c('div',{staticClass:"sector-head"},[_c('h1',{staticClass:"sector-head-title"},[_vm._v(_vm._s(_vm.titles.sector))])]),_c('div',{staticClass:"form-field-plus"},[_c('div',{staticClass:"field-plus-legend"},[_vm._v("Clique para adicionar")]),_c('i',{staticClass:"fa fa-long-arrow-right arrow1",attrs:{"aria-hidden":"true"}}),_c('div',[_c('v-btn',{attrs:{"fab":"","dark":"","color":"secondary"},on:{"click":function($event){return _vm.actionCreate()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account-plus ")])],1)],1)]),_c('div',{staticClass:"box-table"},[_c('v-data-table',{staticClass:"data-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.actors,"page":_vm.pagination.page,"items-per-page":_vm.pagination.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.index))]}},{key:"item.avatar",fn:function(ref){return [_c('v-avatar',{attrs:{"color":"#dddddd","size":"40"}})]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getIconActivatedComputed(item.active).color}},[_vm._v(" "+_vm._s(_vm.getIconActivatedComputed(item.active).icon)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateComputed(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.action && item.id === _vm.itemIdActivated)?_c('div',{staticClass:"confirm"},[_c('div',[_vm._v("Confirma remoção?")]),_c('div',[_c('v-btn',{staticClass:"btn-action option confirm-ok",attrs:{"color":"secondary","x-small":"","elevation":"0","rounded":"","dark":""},on:{"click":function($event){return _vm.actionConfirmRemove(item.id, true)}}},[_vm._v(" Sim ")]),_c('v-btn',{staticClass:"btn-action option",attrs:{"color":"red","x-small":"","elevation":"0","rounded":"","dark":""},on:{"click":function($event){return _vm.actionConfirmRemove(item.id, false)}}},[_vm._v(" Não ")])],1)]):_c('div',[_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(item.stateActive),expression:"item.stateActive"}],attrs:{"size":20,"color":"gray","indeterminate":""}}),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!item.stateActive),expression:"!item.stateActive"}],staticClass:"btn-action",attrs:{"title":_vm.getConfigButtonAllowedComputed(item.active).title,"x-small":"","elevation":"1","rounded":"","plain":"","disabled":item.stateActive,"color":_vm.getConfigButtonAllowedComputed(item.active).color},on:{"click":function($event){return _vm.actionToggleStatusActive(item.id)}}},[_vm._v(" "+_vm._s(_vm.getConfigButtonAllowedComputed(item.active).title)+" ")]),_c('v-btn',{attrs:{"icon":"","title":"Editar"},on:{"click":function($event){return _vm.actionEdit(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","title":"Remover"},on:{"click":function($event){return _vm.actionRemove(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pagination.pageCount},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }